<template>
  <div class="memberPage">
    <div class="topSec">
      <img src="../../assets/avatar.jpeg" />
      <div class="infoBox">
        <div class="topLine">
          <span>{{memberInfo.name ||'暂无姓名'}}</span>          
          <Tag v-if="memberInfo.member==1" class="hasBg" color="#ffffff">{{memberInfo.memberTypeName}}</Tag>
          <Tag v-else   color="#ffffff" plain>非会员</Tag>
        </div>
        <div class="topLine bottomLine">
          <span>{{memberInfo.mobile|| "-"}}</span>
          <span v-if="memberInfo.member==1" class="light">{{memberInfo.season?memberInfo.season+'年':"-"}}</span>
        </div>
      </div>
    </div>
    <ul class="list">
      <li @click="goPage('memberType')">
        <div>
          <img src="../../assets/type.png" />
          <span>加入会员</span>
        </div>
        <Icon class="arrow" name="arrow" color="#666" size="14" />
      </li>
      <li @click="goPage('memberRecords')">
        <div>
          <img src="../../assets/pay.png" />
          <span>缴费记录</span>
        </div>
        <Icon class="arrow" name="arrow" color="#666" size="14" />
      </li>
    </ul>
  </div>
</template>

<script>
import { Tag, Icon } from "vant";
import { getMemberInfo } from "@/api/member.js";
import $ from "jquery";

export default {
  name: "memberPay",
  components: {
    Tag,
    Icon,
  },
  data() {
    return {
      isMember: true, //是否是会员
      memberInfo:{},
    };
  },
  created() {
    //this.getCode();
    let clientId = localStorage.getItem("clientId");
    this.getMember(clientId);
  },
  methods: {
    getCode() {
      if (
        localStorage.getItem("openid") &&
        localStorage.getItem("openid") != "undefined"
      ) {
        return false;
      }
      var code = this.getUrlParam("code"); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      var local = "http://192.168.0.68:8080/#/memberPay";
      var APPID = "wxcedde571c64f953f";
      if (code == null || code === "") {
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          APPID +
          "&redirect_uri=" +
          encodeURIComponent(local) +
          "&response_type=code&scope=snsapi_base&state=#wechat_redirect";
      } else {
          alert(code)
        //this.getOpenId(code); //把code传给后台获取用户信息
      }
    },
    //把code传给后台,得到openid
    getOpenId(code) {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: "xxx",
        data: { code: code },
        success: function(res) {
          if (res.status == -1) {
            // 提示没有关注公众号 没有关注公众号跳转到关注公众号页面
            console.log("您还未关注公众号喔");
            //二维码弹窗           
            return;
          } else {
            // 本地存储这个openid，并刷新页面
            localStorage.setItem("openid", res.data.openid);
            //this.getMember( res.data.openid);
          }
        },
      });
    },
    //获取地址栏的参数
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },   
    getMember(id) {
        getMemberInfo({id}).then(res=>{
            if(res.status=="S"){
                this.memberInfo=res.data
                localStorage.setItem("mobile",res.data.mobile)
            }
        })
    },
    goPage(type) {
      if (type == "memberType") {
         let type=this.memberInfo.memberType?this.memberInfo.memberType:'0'
        this.$router.push(`/memberType?id=${this.memberInfo.id}&memberType=${type}`);
      } else {
        this.$router.push("/memberRecords");
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep.van-tag {
  padding: 0.05rem 0.1rem;
  border-radius: 3px;
  font-size: 12px;
  font-weight: normal;
  &.hasBg {
    background: url("./../../assets/btnBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #2b72ff;
    text-shadow: 0px 0px 4px rgba(0, 0, 255, 0.3);
  }
}

::v-deep.van-tag--plain {
  background: transparent;
}
.memberPage {
  width: 100vw;
  height: 100vh;
  background: #f5f5f5;
  .topSec {
    height: 1.6rem;
    background: url("../../assets/loginBg.png");
    background-size: 100% 120%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.3rem;
    img {
      flex: 0 0 0.6rem;
      height: 0.6rem;
      border-radius: 0.3rem;
      margin-right: 0.15rem;
    }
    .infoBox {
      flex: 1;
      height: 0.6rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      color: #fff;
      .topLine {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
      }
      .bottomLine {
        font-size: 12px;
        font-weight: normal;
        .light {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .list {
    width: 100%;
    background: #fff;
    border-radius: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    padding: 0 0.3rem;
    box-sizing: border-box;
    li {
      height: 0.6rem;
      border-bottom: 1px solid #eee;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #333;
      &:last-child {
        border-bottom: 0px solid #eee;
      }
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 0.17rem;
          margin-right: 0.22rem;
        }
      }
    }
  }
}
</style>
